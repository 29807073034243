import { graphql, PageProps } from 'gatsby';
import { Box } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';

interface AboutPageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function AboutPage({ data }: AboutPageProps) {
    const { translate } = useLocale();
    return (
        <Box py={20}>
            <SEO
                title={
                    data.page.title
                        ? translate(data.page.title)
                        : 'Acerca de En Base Studio'
                }
            />
            <Content data={data.page.content} />
        </Box>
    );
}

export const query = graphql`
    query AboutPageQuery($language: String!) {
        page: sanityPage(
            slug: { current: { eq: "acerca-de-en-base-studio" } }
        ) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
